<template>
  <base-section id="theme-features">
    <base-section-heading :title="$t('features_headline')">
    </base-section-heading>

    <v-container>
      <v-row>
        <v-col
          v-for="(feature, i) in features[$i18n.locale]"
          :key="i"
          cols="12"
          md="6"
        >
          <base-avatar-card
            v-bind="feature"
            align="left"
            horizontal
          >
            {{ feature.text }}
          </base-avatar-card>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionFeaturesReporting',

    data: () => ({
      features: {
        en: [
          {
            title: 'Adapted to your business',
            icon: 'mdi-format-align-center',
            text: 'All organisations are different and have different ambitions when it comes to sustainability. We adapt the platform to your business needs.',
          },
          {
            title: 'Clear overview',
            icon: 'mdi-view-dashboard',
            text: 'All results and data are presented in graphics on both an individual and an aggregated level, giving a clear overview and making in-depth analysis easy.',
          },
          {
            title: 'Knowledge is key',
            icon: 'mdi-traffic-light',
            text: 'Evaluate gives insights on sustainability performance and identifies risks in the entire value chain. ',
          },
          {
            title: 'Cost-efficient',
            icon: 'mdi-cash',
            text: 'Evaluate provides a set of powerful digital tools for cost-efficient sustainability work.',
          },
        ],
        sv: [
          {
            title: 'Anpassat till ditt företag',
            icon: 'mdi-format-align-center',
            text: 'Alla organisationer är olika och har olika ambitioner när det gäller hållbarhet. Vi anpassar plattformen efter dina affärsbehov.',
          },
          {
            title: 'Tydlig överblick',
            icon: 'mdi-view-dashboard',
            text: 'Alla resultat och data presenteras i grafik på både individuell och aggregerad nivå, vilket ger en tydlig överblick och gör en mer djupgående analys enkel.',
          },
          {
            title: 'Kunskap är nyckeln',
            icon: 'mdi-traffic-light',
            text: 'Evaluate ger insikter om hållbarhetsprestanda och identifierar risker i hela värdekedjan.',
          },
          {
            title: 'Kostnadseffektivt',
            icon: 'mdi-cash',
            text: 'Evaluate innebär kraftfulla digitala verktyg som gör ditt hållbarhetsarbete mer kostnadseffektivt.',
          },
        ],
      }
    }),
  }
</script>
